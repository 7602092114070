import * as React from 'react';

function SettingsIcon(
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement {
  return (
    <svg
      width={20}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.613 19.997a2.25 2.25 0 01-1.35-.46l-2.07-1.54a2.37 2.37 0 01-.49-3.219 2.06 2.06 0 00.23-1.86l-.06-.16a1.83 1.83 0 00-1.119-1.22h-.16a2.34 2.34 0 01-1.48-2.94L.934 6a2.18 2.18 0 011.948-1.642c.288-.02.578.018.852.112a1.93 1.93 0 001.78-.29l.13-.1a1.94 1.94 0 00.73-1.51v-.24A2.32 2.32 0 018.663 0h2.549a2.26 2.26 0 011.6.67 2.37 2.37 0 01.68 1.68v.28a1.759 1.759 0 00.69 1.43l.11.08a1.74 1.74 0 001.59.26l.34-.11a2.26 2.26 0 012.879 1.51l.79 2.519a2.36 2.36 0 01-1.46 2.93l-.2.07a1.89 1.89 0 00-1.23 1.28 2 2 0 00.25 1.65l.26.38a2.38 2.38 0 01-.5 3.229l-2.01 1.55a2.239 2.239 0 01-3.22-.53l-.12-.17a1.75 1.75 0 00-1.5-.78 1.799 1.799 0 00-1.429.77l-.23.33a2.25 2.25 0 01-1.5.97 2 2 0 01-.39 0zM2.403 9.62a3.83 3.83 0 012.38 2.5v.12a4 4 0 01-.46 3.619.38.38 0 000 .51l2.15 1.63a.25.25 0 00.37-.07l.23-.33a3.77 3.77 0 016.2 0l.12.18a.3.3 0 00.18.12.25.25 0 00.19-.05l2.06-1.56a.36.36 0 00.07-.49l-.26-.38a4 4 0 01-.53-3.42 3.92 3.92 0 012.489-2.61l.2-.07a.34.34 0 00.19-.44l-.78-2.489a.35.35 0 00-.2-.19.21.21 0 00-.19 0l-.34.11a3.74 3.74 0 01-3.43-.57l-.04-.09a3.76 3.76 0 01-1.49-3v-.31a.37.37 0 00-.1-.26.31.31 0 00-.21-.08H8.664a.31.31 0 00-.29.33v.25a3.9 3.9 0 01-1.52 3.09l-.13.1a3.91 3.91 0 01-3.63.59.22.22 0 00-.14 0 .28.28 0 00-.12.15l-.83 2.61a.36.36 0 00.22.45l.18.05z"
        fill="#fff"
      />
      <path
        d="M10.003 13.498a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm0-4.999a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="#fff"
      />
    </svg>
  );
}

export default SettingsIcon;
